import React from 'react'
import { Link } from 'gatsby'
import '../components/globalStyle.css'
import '../components/backButton.css'

// just css+html
// const BackButton = () => {
//
//   return (
//
//     <div className= "backButton">
//       <p>
//         <Link to="/">
//           <div className= "backText">back</div>
//         </Link>
//       </p>
//     </div>
//   )
// }

// some crazy react stuff

class BackButton extends React.Component {
  constructor() {
    super()
    this.state  = {
      hover: false
    }

    this.toggleHover = this.toggleHover.bind(this)
  }

  toggleHover () {
    this.setState({hover: !this.state.hover})
  }
  render() {
    let icon = <img className= "backIcon" src={require('../img/backIcon.svg')} alt="backArrow"/>
    if(this.state.hover) icon = <img className= "backIcon" src={require('../img/backIconHover.svg')} alt="redBackArrow"/>

    return (
      <div className="backButton" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
        <Link to="/">
          {icon}
          <br></br>
          back
        </Link>
      </div>
    )
  }
}
export default BackButton
