import React from 'react'
import { Link } from 'gatsby'

import '../components/globalStyle.css'
import '../components/product.css'

import Header from '../components/header'
import BackButton from '../components/backButton'


const productInfura = () => (
  <div className= "productInfuraGroup">

    <Header />

    <BackButton />

    <div className="product">

      <div className="productText">

        <h2> INFURA: DEVELOPER DASHBOARD </h2>
        <p className="productInfo">
          <i>
            June 2018 - Nov 2018<br></br>
            Designed w/ Andrew Cohen<br></br>
          </i>
        </p>

        <p>
          Infura is a developer tool key to the Ethereum blockchain's infrastructure. It allows people to connect to a distributed node network that acts as Ethereum's decentralized alternative to servers. Nodes are really hard for devs to build and maintain on their own. Infura is a cloud-based computing service that does that work for them, so they can focus on coding. If you're interested in what they do, <a href="https://blog.infura.io/say-hello-to-infura-a64f40c6f5e" target="blank"><u>here is an article you can read.</u></a>
          <br></br>
          <br></br>
          Andrew and I were building a developer dashboard for people to track how their applications were interacting with nodes. We found that developers were frustrated by how opaque their apps were. For example, they had no idea how much traffic they were causing, where usage was heavy and slow, or what trends could be found in their users' activity. The dashboard was built to offer clear and transparent metrics measuring the activity important to developers.
        </p>
        <h4> Generative Reseach </h4>


      </div>

      <figure className="productImg">
        <img src={require('../img/infura/infuraGenerative2.svg')} />
        <figcaption>I made and followed this interview guide, and used it to extract key take aways that helped validate my initial assumptions, generate new learnings, and gain a sense of context.</figcaption>
      </figure>

      <div className="productText">
        <h4> Sketches and Prototyping </h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/infura/infuraSketches.png')} />
        <figcaption>After the initial interviews, we used Mural to apply our learnings, collaboratively organize and prioritize features, and sketch first passes at lo-fi prototypes</figcaption>
      </figure>



      <figure className="productImg">
        <img src={require('../img/infura/infuraDashV1.gif')} />
        <figcaption>I used the existing design system to translate sketches into an MVP prototype</figcaption>
      </figure>


      <div className="productText">
        <h4> Iteration </h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/infura/infuraAnalyticsModules2.svg')} />
        <figcaption>We talked to the team and re-evaluated beased on thier feedback and built a new iteration of our prototype. This version better fit the tech stack and offered our users more information. It also proved to be a more modular and scalable solution.</figcaption>
      </figure>

      <div className="productText">
        <p>
           Infura launched their developer dashboard a few weeks after. You can see it <a href= "https://blog.infura.io/infura-dashboard-update-9f02d0643eb3"><u>here</u></a>.
        </p>
      </div>

    </div>
  </div>

      )

export default productInfura
