import React from 'react'
import { Link } from 'gatsby'
import '../components/header.css'

const Header = ({ siteTitle }) => (

  <div className="header">
    <div className= "nameAndContact">
      <div id="name">
        <h1>
          <Link to= "../">CELINE  PARK</Link>
        </h1>
      </div>

      <div id="contact">
        <p>
          hmu:<a href= "mailto:thisiscelinepark@gmail.com"> <span id= "headerEmail"> thisiscelinepark@gmail.com</span></a>
        </p>
      </div>
    </div>

    <div className="nav">

        <p>\\<Link to="/">PRODUCT DESIGN</Link></p>
        <p>\\<Link to="/aboutMe/">ABOUT ME</Link></p>

    </div>

  </div>

)

export default Header
